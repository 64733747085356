import React, {useState} from 'react'
import {Form, Button} from 'react-bootstrap-v5'

import {useDispatch} from 'react-redux'
import {createUserRequest} from '../../../../store/ducks/users/actions'

import {User} from '../../../../store/ducks/me/types'

// var bcrypt = require('bcryptjs');

interface handleCloseProps {
  handleClose: () => void
}

const Create = ({handleClose}: handleCloseProps) => {
  // const [sending, setSending] = useState(false);
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [whatsapp, setWhatsapp] = useState('')
  const [cpf, setCpf] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const [bairro, setBairro] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  // let history = useHistory();

  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    console.log('handleSubmit')
    if (
      name &&
      username &&
      email &&
      password &&
      whatsapp &&
      cpf &&
      address &&
      number &&
      bairro &&
      city &&
      state
    ) {
      console.log('handleSubmit2')
      var data = new Date()
      const user: User = {
        username,
        email,
        // password_hash: password,
        newPassword: password,
        // auth_key: 'teste',
        // registration_ip: '0',
        created_at: data.getTime() / 1000,
        // updated_at: '0',
        flags: 10,

        profile: {
          name,
          // public_email: '',
          // gravatar_email: '',
          // gravatar_id: '',
          // location: '',
          // website: '',
          // bio: '',
          // timezone: '',
          whatsapp,
          cpf,
          endereco: address + ', ' + number + ' - ' + bairro + '. ' + city + ' / ' + state,
        },
      }

      dispatch(createUserRequest(user))
      handleClose()
    }
  }

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId='Name'>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={name}
            onChange={(e:any) => setName(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='Username'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={username}
            onChange={(e:any) => setUsername(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            Por favor informe o username. Username pode ser o mesmo valor do e-mail.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Email'>
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={email}
            onChange={(e:any) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o email</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Senha'>
          <Form.Label>Senha</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={password}
            onChange={(e:any) => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe a senha</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Whatsapp'>
          <Form.Label>Whatsapp</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={whatsapp}
            onChange={(e:any) => setWhatsapp(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            Por favor informe o número de whatsapp
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='CPF'>
          <Form.Label>CPF</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={cpf}
            onChange={(e:any) => setCpf(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o cpf</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Endereco'>
          <Form.Label>Endereço</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={address}
            onChange={(e:any) => setAddress(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o endereço</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Numero'>
          <Form.Label>Numero</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={number}
            onChange={(e:any) => setNumber(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o número</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Bairro'>
          <Form.Label>Bairro</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={bairro}
            onChange={(e:any) => setBairro(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o número</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Cidade'>
          <Form.Label>Cidade</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={city}
            onChange={(e:any) => setCity(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe a cidade</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='Estado'>
          <Form.Label>Estado</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={state}
            onChange={(e:any) => setState(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o estado</Form.Control.Feedback>
        </Form.Group>
        <Button variant='primary' type='submit'>
          Submit
        </Button>
      </Form>
    </>
  )
}
export default Create
