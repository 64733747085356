/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Button, FormControl, InputGroup, Modal} from 'react-bootstrap-v5'

import {KTSVG} from '../../../design/helpers'
import {useDispatch} from 'react-redux'

// import {loadLeadsRequest, searchLeadsRequest} from '../../../../store/ducks/leads/actions'
import {UsersState} from '../../../../store/ducks/users/types'
import {User} from '../../../../store/ducks/me/types'
import {
  deleteUserRequest,
  loadUsersRequest,
  searchUserRequest,
} from '../../../../store/ducks/users/actions'

import Info from './info'
import Create from './create'
import Update from './update'
import Pagination from '../../../../customHooks/Pagination'

type Props = {
  className: string
  users: UsersState
  page?: string
  take?: string
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
}

const ManageUsersWidget: React.FC<React.PropsWithChildren<Props>> = ({className, users, page, take, setCurrentPage, currentPage}) => {
  const [search, setSearch] = useState('')
  const [show, setShow] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [child, setChild] = useState<User>({})
  console.log("users", users)
  // const [extra, setExtra] = useState<Extras>({});
  const dispatch = useDispatch()
  // const history = useHistory();

  // const searchLeads = () => {
  //   console.log('search', search)
  //   if (search) dispatch(searchLeadsRequest(search))
  //   else dispatch(loadLeadsRequest())
  // }

  const handleClose = () => {
    setShow(false)
  }

  const createUser = () => {
    setAction('createUser')
    setShow(true)
  }

  const updateUser = (user: User) => {
    setAction('editUser')
    setShow(true)
    setChild(user)
  }

  const infoUser = (user: User) => {
    setAction('infoUser')
    setShow(true)
    setChild(user)
  }

  const deleteUser = (user: User) => {
    console.log('deletar', user.id)
    dispatch(deleteUserRequest(user.id!))
  }

  const searchUser = () => {
    console.log('search', search)
    if (search) dispatch(searchUserRequest(search))
    else dispatch(loadUsersRequest(+page!,+take!))
  }
  let count = users.count
  // let pages = Math.ceil(+count! / +take!)
  let length = users.data?.length
  return (
    <div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {action === 'infoUser' ? 'Informações do usuário' : ''}
            {action === 'editUser' ? 'Editar usuário' : ''}
            {action === 'createUser' ? 'Adicionar usuário' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action === 'infoUser' ? <Info handleClose={handleClose} child={child} /> : ''}
          {action === 'editUser' ? <Update handleClose={handleClose} child={child} /> : ''}
          {action === 'createUser' ? <Create handleClose={handleClose} /> : ''}
        </Modal.Body>
      </Modal>
      <InputGroup className='mb-3'>
        <FormControl
          placeholder='Pesquise por e-mail, nome, ...'
          aria-label='Pesquisa'
          aria-describedby='basic-addon2'
          value={search}
          onChange={(e:any) => setSearch(e.target.value)}
        />
        {/* <InputGroup.Append> */}
        <Button variant='outline-secondary' onClick={searchUser}>
          Pesquisar
        </Button>
        {/* </InputGroup.Append> */}
      </InputGroup>
      <div style={{marginLeft: 5}}>A pesquisa retornou: {count} dados</div> 
      {!search?
        <div style={{marginLeft: 5}}>Mostrando: {(+page! - 1) * (+take!) + 1} - {(+page! - 1) * (+take!) + +length!}</div>
        : ''
      }
      <div>{users.error?'Tem erro':''}</div>
      <br />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Usuários</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Usuários na plataforma</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
              onClick={() => createUser()}
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo usuário
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Nome</th>
                  <th className='min-w-140px'>Email</th>
                  <th className='min-w-120px'>Whatsapp</th>
                  <th className='min-w-120px'>Created_at</th>
                  <th className='min-w-120px'>Flags</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {users.data?.map((child, index) => {
                  return (
                    <tr key={index}>
                      {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                        </div>
                      </td> */}
                      <td>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/avatars/150-11.jpg')} alt='' />
                          </div> */}
                          <div className='d-flex justify-content-start flex-column'>
                            {child.profile?.name}
                            {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {child.description}
                            </span> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          Intertico
                        </a> */}
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.email}
                        </span>
                      </td>
                      <td>
                        {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          Intertico
                        </a> */}
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          <a
                            href={'https://api.whatsapp.com/send?phone=' + child.profile?.whatsapp}
                            target='_blank'
                          >
                            {child.profile?.whatsapp}
                          </a>
                        </span>
                      </td>
                      <td>
                        {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          Intertico
                        </a> */}
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.created_at}
                        </span>
                      </td>
                      <td>
                        {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          Intertico
                        </a> */}
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.flags}
                        </span>
                      </td>

                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            onClick={() => infoUser(child)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            onClick={() => updateUser(child)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            onClick={() => {
                              if (
                                window.confirm('Deseja realmente excluir: ' + child.username + '?')
                              )
                                deleteUser(child)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {!search?   
      <Pagination
        className=""
        currentPage={currentPage!}
        totalCount={count}
        pageSize={take}
        onPageChange={(page:any) => setCurrentPage(page)}
        link="users"
      />
      :''}
    </div>
  )
}

export {ManageUsersWidget}
