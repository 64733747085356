import React, {Suspense, lazy} from 'react'
// import { Provider } from 'react-redux';
// import store from '../../store';
import {Navigate, Route, Routes} from 'react-router-dom'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
import Wppcamp from '../pages/admin/wppcamp'
import Wppgroup from '../pages/admin/wppgroup'
import Leads from '../pages/admin/leads'
import Users from '../pages/admin/users'
import EmailsEnviados from '../pages/admin/emailsenviados'
import Contact from '../pages/admin/contact'
import Annotations from '../pages/admin/annotations'
import ManageCampaign from '../pages/admin/campaigns/ManageCampaign'
import ManageBloodcenter from '../pages/admin/bloodcenter/ManageBloodcenter'

export function PrivateRoutes() {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const Manage = lazy(() => import('../pages/admin/component/Manage'))

  return (
      <Routes>
        <Route path='dashboard' element={<DashboardWrapper/>} />
        <Route path="manage/:id" element={<Manage/>} />
        <Route path='/wppcamp' element={<Wppcamp/>} />
        <Route path="wppgroup">
          <Route path=":id" element={<Wppgroup/>}/>
        </Route>
        <Route path='leads/:page/:take' element={<Leads/>} />
        <Route path='users/:page/:take' element={<Users/>} />
        <Route path='emailsenviados' element={<EmailsEnviados/>} />
        <Route path='contact' element={<Contact/>} />
        <Route path='annotations' element={<Annotations/>} />
        <Route path='/campaigns' element={<ManageCampaign/>} />
        <Route path='/bloodcenter' element={<ManageBloodcenter/>} />
        
        
        <Route path ="/auth/login" element={<Navigate to="/campaigns"/>} />
        <Route path="index.html" element={<Navigate to="/campaigns"/>} />
        <Route path="/index.html" element={<Navigate to="/campaigns"/>} />
        <Route index element={<Navigate to="/campaigns"/>} />
        {/* <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' /> */}
      </Routes>
    
  )
}
