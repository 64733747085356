import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../../design/layout/core'
// import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import {loadComponentRequest} from '../../../../store/ducks/component/actions'
import {ComponentState} from '../../../../store/ducks/component/types'

import {ManageItemWidget} from './ManageItemWidget'

import { loadCampaignRequest, loadCampaignsRequest, loadMyCampaignsRequest } from '../../../../store/ducks/campaign/actions'
import { CampaignState } from '../../../../store/ducks/campaign/types'
import Loading from '../../../loading'
import { loadBloodcenters } from '../../../../store/ducks/bloodcenter/sagas'
import { loadBloodcentersRequest } from '../../../../store/ducks/bloodcenter/actions'
import { BloodcenterState } from '../../../../store/ducks/bloodcenter/types'

type ParamTypes = {
  id: string
}

type Props = {
  bloodcenter: BloodcenterState
}

const ManagePage: React.FC<React.PropsWithChildren<Props>> = ({bloodcenter}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      <div className='col-xxl-12'>
        <ManageItemWidget bloodcenter={bloodcenter} className='card-xxl-stretch mb-5 mb-xxl-7' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const ManageBloodcenter: FC<React.PropsWithChildren<unknown>> = () => {
  // const intl = useIntl()

  const dispatch = useDispatch()
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  const bloodcenter = useSelector((state: ApplicationState) => state.bloodcenter)
  const me = useSelector((state: ApplicationState) => state.me)
  //let {id} = useParams<ParamTypes>()
  console.log("ME", me.me.id)

  useEffect(() => {
    dispatch(loadBloodcentersRequest()) //Puxa componentes com seus filhos primários
  }, [])

  console.log("MY CAMPAIGNS", campaign)

  if (campaign.loading) return <Loading />

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
      <PageTitle breadcrumbs={[]}>Hemocentros</PageTitle>
      <ManagePage bloodcenter={bloodcenter} />
    </>
  )
}
export default ManageBloodcenter
