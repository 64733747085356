import {useEffect, useState} from 'react'
import {InputGroup, FormControl, Form, Button} from 'react-bootstrap-v5'
import {ApplicationState} from '../../../../store'
import {useSelector, useDispatch} from 'react-redux'
import {loadListsRequest} from '../../../../store/ducks/lists/actions'
import {createEmailToListRequest} from '../../../../store/ducks/email/actions'
import {EmailTolist} from '../../../../store/ducks/email/types'
import Loading from '../../../loading'

interface handleCloseProps {
  handleClose: () => void
}

const Leads = ({handleClose}: handleCloseProps) => {
  const dispatch = useDispatch()
  const lists = useSelector((state: ApplicationState) => state.lists)
  const me = useSelector((state: ApplicationState) => state.me)
  const [list, setList] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    dispatch(loadListsRequest())
  }, [dispatch])

  // console.log('listsxxx', lists)
  // console.log('emailToList', emailToList)
  // console.log('me', me)

  if (lists.loading) return <Loading />

  const sendEmail = () => {
    console.log('send')
    console.log('list', list)
    console.log('subject', subject)
    console.log('message', message)
    var data = new Date()
    const emailToListNew: EmailTolist = {
      list,
      subject,
      message,
      parentUser: me.me.id,
      user_id: me.me.id,
      status: 1,
      created_at: (data.getTime() / 1000).toString(),
    }
    dispatch(createEmailToListRequest(emailToListNew))
    handleClose()
  }

  return (
    <>
      <Form.Group>
        <Form.Control as='select' value={list} onChange={(e) => setList(e.target.value)}>
          <option>Selecione uma lista</option>
          {lists.data.map((list) => {
            return <option>{list.list}</option>
          })}
        </Form.Control>
      </Form.Group>

      <InputGroup className='mb-3'>
        <FormControl
          placeholder='Assunto'
          aria-label='Assunto'
          aria-describedby='basic-addon2'
          value={subject}
          onChange={(e:any) => setSubject(e.target.value)}
        />
      </InputGroup>

      <InputGroup>
        {/* <InputGroup.Prepend> */}
        <InputGroup.Text>Mensagem</InputGroup.Text>
        {/* </InputGroup.Prepend> */}
        <FormControl
          as='textarea'
          aria-label='With textarea'
          rows={10}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </InputGroup>
      <br />
      <Button variant='outline-secondary' onClick={sendEmail}>
        Enviar email
      </Button>
    </>
  )
}

export default Leads
