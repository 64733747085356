import {call, put} from 'redux-saga/effects'
import api from '../../../services/api'

import {
  loadCampaignsFailure,
  loadCampaignsRequest,
  loadCampaignsSuccess,

  loadMyCampaignsFailure,
  loadMyCampaignsRequest,
  loadMyCampaignsSuccess,

  createCampaignFailure,
  createCampaignRequest,
  createCampaignSuccess,

  updateCampaignFailure,
  updateCampaignRequest,
  updateCampaignSuccess,

  deleteCampaignFailure,
  deleteCampaignRequest,
  deleteCampaignSuccess,

  loadCampaignRequest,
  loadCampaignSuccess,
  loadCampaignFailure,
  searchCampaignsRequest,
  searchCampaignsSuccess,
  searchCampaignsFailure


  // loadCourseRequest,
} from './actions'

import {Campaign} from './types'

// import { loadExtrasSuccess } from '../extras/actions'
import {Extras} from '../extras/types'
// import axios from 'axios';

//Load Campaign
export function* loadCampaigns(payload: ReturnType<typeof loadCampaignsRequest>) {
  try {
    put(loadCampaignsRequest(payload.payload.page, payload.payload.take))
    const response: Campaign = yield call(api.get, `campaigns`)
    yield put(loadCampaignsSuccess(response))
  } catch (error) {
    yield put(loadCampaignsFailure())
  }
}

//Load My Campaigns
export function* loadMyCampaigns(payload: ReturnType<typeof loadMyCampaignsRequest>) {
  try {
    // console.log("entrei1")
    put(loadMyCampaignsRequest(payload.payload.id))
    // console.log("entrei2")
    const response: Campaign = yield call(api.get, 'mycampaigns/' + payload.payload.id)
    // console.log("entrei3")
    yield put(loadMyCampaignsSuccess(response))
    // console.log("entrei4")
  } catch (error) {
    // console.log("Deu pau")
    yield put(loadMyCampaignsFailure())
  }
}

//Search
export function* searchCampaigns(payload: ReturnType<typeof searchCampaignsRequest>) {
  try {
    put(searchCampaignsRequest(payload.payload.text))
    const response: Campaign = yield call(api.get, 'searchcampaign/' + payload.payload.text)
    yield put(searchCampaignsSuccess(response))
  } catch (error) {
    yield put(searchCampaignsFailure())
  }
}

//Load Campaign
export function* loadCampaign(payload: ReturnType<typeof loadCampaignRequest>) {
  try {
    put(loadCampaignRequest(payload.payload.id))
    const response: Campaign = yield call(api.get, 'campaign/' + payload.payload.id)
    yield put(loadCampaignSuccess(response))
  } catch (error) {
    yield put(loadCampaignFailure())
  }
}

//Create Campaign
export function* createCampaign(payload: ReturnType<typeof createCampaignRequest>) {
  try {
    const response: Campaign = yield call(api.post, 'campaign', payload.payload)
    yield put(createCampaignSuccess(response))
  } catch (error: any) {
    yield put(createCampaignFailure(error.response.data))
  }
}

//Update Campaign
export function* updateCampaign(payload: ReturnType<typeof updateCampaignRequest>) {
  try {
    put(updateCampaignRequest(payload.payload))
    const response: Campaign = yield call(api.post, 'campaign', payload.payload)
    yield put(updateCampaignSuccess(response))
  } catch (error) {
    yield put(updateCampaignFailure())
  }
}

//Delete Campaign
export function* deleteCampaign(payload: ReturnType<typeof deleteCampaignRequest>) {
  console.log("DELETANDO CAMPANHA", payload)
  try {
    const number: number = yield call(api.delete, 'campaign/' + payload.payload)
    console.log("NUMBER", number)
    yield put(deleteCampaignSuccess(number))
  } catch (error) {
    yield put(deleteCampaignFailure())
  }
}
