// import { ComponentAvailable } from '../componentavailable/types';
import { City } from '../city/types'
import { User } from '../me/types'
import { State } from '../state/types'
/**
 * Action types
 */
export enum CampaignTypes {
  //Load campaigns
  LOAD_CAMPAIGNS_REQUEST = '@campaign/LOAD_CAMPAIGNS_REQUEST',
  LOAD_CAMPAIGNS_SUCCESS = '@campaign/LOAD_CAMPAIGNS_SUCCESS',
  LOAD_CAMPAIGNS_FAILURE = '@campaign/LOAD_CAMPAIGNS_FAILURE',

  //Load campaigns
  LOAD_MYCAMPAIGNS_REQUEST = '@campaign/LOAD_MYCAMPAIGNS_REQUEST',
  LOAD_MYCAMPAIGNS_SUCCESS = '@campaign/LOAD_MYCAMPAIGNS_SUCCESS',
  LOAD_MYCAMPAIGNS_FAILURE = '@campaign/LOAD_MYCAMPAIGNS_FAILURE',

  //Campaign
  LOAD_CAMPAIGN_REQUEST = '@campaign/LOAD_CAMPAIGN_REQUEST',
  LOAD_CAMPAIGN_SUCCESS = '@campaign/LOAD_CAMPAIGN_SUCCESS',
  LOAD_CAMPAIGN_FAILURE = '@campaign/LOAD_CAMPAIGN_FAILURE',

  //Create component
  CREATE_CAMPAIGN_REQUEST = '@campaign/CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_SUCCESS = '@campaign/CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE = '@campaign/CREATE_CAMPAIGN_FAILURE',

  //Update component
  UPDATE_CAMPAIGN_REQUEST = '@campaign/UPDATE_CAMPAIGN_REQUEST',
  UPDATE_CAMPAIGN_SUCCESS = '@campaign/UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAILURE = '@campaign/UPDATE_CAMPAIGN_FAILURE',

  //Delete component
  DELETE_CAMPAIGN_REQUEST = '@campaign/DELETE_CAMPAIGN_REQUEST',
  DELETE_CAMPAIGN_SUCCESS = '@campaign/DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_FAILURE = '@campaign/DELETE_CAMPAIGN_FAILURE',

  //Search
  SEARCH_CAMPAIGN_REQUEST = '@campaign/SEARCH_CAMPAIGN_REQUEST',
  SEARCH_CAMPAIGN_SUCCESS = '@campaign/SEARCH_CAMPAIGN_SUCCESS',
  SEARCH_CAMPAIGN_FAILURE = '@campaign/SEARCH_CAMPAIGN_FAILURE',

}

/**
 * Data types
 */
// User Imported from Me
export interface Campaign {
  id?: number
  created_at?: string
  name?: string
  bloodtype?: string
  image?: string
  status?: number | boolean
  bcname?: string
  bcaddress?: string
  bccity?: string
  bcstate?: string
  bccountry?: string
  tags?: string
  user_id?: number
  parentUser?: User
  cityParent?: City
  stateParent?: State

}
/**
 * State type
 */
export interface CampaignState {
  readonly data: Campaign[]
  readonly loading: boolean
  readonly error: boolean

  readonly mycampaigns: Campaign[]
  readonly loadingMycampaigns: boolean
  readonly errorMycampaigns: boolean

  readonly campaign: Campaign
  readonly loadingCampaign: boolean
  readonly errorCampaign: boolean

  readonly search: Campaign[]
  readonly loadingSearch: boolean
  readonly errorSearch: boolean

  count?: number
  page?: number
  
  
}
