import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap-v5'

// import {useParams, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {updateUserRequest} from '../../../../store/ducks/users/actions'
// import {ApplicationState} from '../../../../store'

import {User} from '../../../../store/ducks/me/types'

interface handleCloseProps {
  handleClose: () => void
  child: User
}

const Update = ({handleClose, child}: handleCloseProps) => {
  const [name, setName] = useState<string | undefined>('')
  const [username, setUsername] = useState<string | undefined>('')
  const [email, setEmail] = useState<string | undefined>('')
  const [password, setPassword] = useState<string | undefined>('')

  const [oldpasswordhash, setOldpasswordhash] = useState<string | undefined>('')

  const [whatsapp, setWhatsapp] = useState<string | undefined>('')
  const [cpf, setCpf] = useState<string | undefined>('')
  const [address, setAddress] = useState<string | undefined>('')
  const [validated, setValidated] = useState<boolean>(false)

  // const navigate = useNavigate()
  // const users = useSelector((state: ApplicationState) => state.users)

  const dispatch = useDispatch()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    console.log('handle submit')
    setValidated(true)
    if (name && username && email && whatsapp && cpf && address) {
      console.log('handle submit2')

      console.log('Update User')
      const userToUpdate = {
        id: child.id,
        username,
        email,
        newPassword: password,
        profile: {
          user_id: child.id,
          name,
          whatsapp,
          cpf,
          endereco: address,
        },
      }

      console.log('user to save', userToUpdate)
      dispatch(updateUserRequest(userToUpdate))
      handleClose()
    }
  }

  useEffect(() => {
    setName(child.profile?.name)
    setUsername(child.username)
    setEmail(child.email)
    setOldpasswordhash(child.password_hash)
    setWhatsapp(child.profile?.whatsapp)
    setCpf(child.profile?.cpf)
    setAddress(child.profile?.endereco)
  }, [])

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId='fromName'>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={name}
            onChange={(e:any) => setName(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='fromName'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={username}
            onChange={(e:any) => setUsername(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            Por favor informe o username. Username pode ser o mesmo valor do e-mail.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='fromName'>
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            placeholder=''
            required
            value={email}
            onChange={(e:any) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o email</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='fromName'>
          <Form.Label>Senha</Form.Label>
          <Form.Control
            placeholder='Deixe em branco para não editar a senha atual do usuário'
            value={password}
            onChange={(e:any) => setPassword(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe a senha</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='fromName'>
          <Form.Label>Whatsapp</Form.Label>
          <Form.Control
            placeholder=''
            value={whatsapp}
            onChange={(e:any) => setWhatsapp(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            Por favor informe o número de whatsapp
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='fromName'>
          <Form.Label>CPF</Form.Label>
          <Form.Control placeholder='' value={cpf} onChange={(e:any) => setCpf(e.target.value)} />
          <Form.Control.Feedback type='invalid'>Por favor informe o cpf</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='fromName'>
          <Form.Label>Endereço</Form.Label>
          <Form.Control
            placeholder=''
            value={address}
            onChange={(e:any) => setAddress(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Por favor informe o endereço</Form.Control.Feedback>
        </Form.Group>

        <Button variant='primary' type='submit'>
          Submit
        </Button>
      </Form>
      {/* Deixar o button fora do form.. */}
    </>
  )
}
export default Update
