import React from 'react'
import {User} from '../../../../store/ducks/me/types'

interface handleCloseProps {
  handleClose: () => void
  child: User
}

const InfoUser = ({handleClose, child}: handleCloseProps) => {
  // const {id} = useParams();

  // var data = new Date(apiResponse.created_at*1000);
  return (
    <>
      <h1>{child.username}</h1>
      <p>{child.email}</p>
      <p>{child.profile?.name}</p>
      <p>{child.profile?.cpf}</p>
      <p>{child.profile?.whatsapp}</p>
      <p>{child.profile?.endereco}</p>
      <p>{child.created_at}</p>
      <h6>{/* Criado em: {data.toLocaleDateString('pt-BR')} */}</h6>

      <p></p>
      <p>Está em quais cursos: </p>
    </>
  )
}
export default InfoUser
