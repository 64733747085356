import {call, put} from 'redux-saga/effects'
import api from '../../../services/api'

import {

  loadBloodcentersFailure,
  loadBloodcentersRequest,
  loadBloodcentersSuccess,

  loadBloodcenterFailure,
  loadBloodcenterRequest,
  loadBloodcenterSuccess,

  searchBloodcenterFailure,
  searchBloodcenterRequest,
  searchBloodcenterSuccess,


  createBloodcenterFailure,
  createBloodcenterRequest,
  createBloodcenterSuccess,

  updateBloodcenterFailure,
  updateBloodcenterRequest,
  updateBloodcenterSuccess,

  deleteBloodcenterFailure,
  deleteBloodcenterRequest,
  deleteBloodcenterSuccess


  // loadCourseRequest,
} from './actions'

import {Bloodcenter} from './types'

// import { loadExtrasSuccess } from '../extras/actions'
import {Extras} from '../extras/types'
// import axios from 'axios';

//Load Campaign
export function* loadBloodcenters(payload: ReturnType<typeof loadBloodcentersRequest>) {
  try {
    put(loadBloodcentersRequest())
    const response: Bloodcenter = yield call(api.get, `bloodcenters`)
    yield put(loadBloodcentersSuccess(response))
  } catch (error) {
    yield put(loadBloodcentersFailure())
  }
}

//Search
export function* searchBloodcenter(payload: ReturnType<typeof searchBloodcenterRequest>) {
  try {
    put(searchBloodcenterRequest(payload.payload.text))
    const response: Bloodcenter = yield call(api.get, 'searchbloodcenter/' + payload.payload.text)
    yield put(searchBloodcenterSuccess(response))
  } catch (error) {
    yield put(searchBloodcenterFailure())
  }
}

//Load Campaign
export function* loadBloodcenter(payload: ReturnType<typeof loadBloodcenterRequest>) {
  try {
    put(loadBloodcenterRequest(payload.payload.id))
    const response: Bloodcenter = yield call(api.get, 'bloodcenter/' + payload.payload.id)
    yield put(loadBloodcenterSuccess(response))
  } catch (error) {
    yield put(loadBloodcenterFailure())
  }
}

//Create Campaign
export function* createBloodcenter(payload: ReturnType<typeof createBloodcenterRequest>) {
  try {
    const response: Bloodcenter = yield call(api.post, 'bloodcenter', payload.payload)
    yield put(createBloodcenterSuccess(response))
  } catch (error: any) {
    yield put(createBloodcenterFailure(error.response.data))
  }
}

//Update Campaign
export function* updateBloodcenter(payload: ReturnType<typeof updateBloodcenterRequest>) {
  try {
    put(updateBloodcenterRequest(payload.payload))
    const response: Bloodcenter = yield call(api.post, 'bloodcenter', payload.payload)
    yield put(updateBloodcenterSuccess(response))
  } catch (error) {
    yield put(updateBloodcenterFailure())
  }
}

//Delete Campaign
export function* deleteBloodcenter(payload: ReturnType<typeof deleteBloodcenterRequest>) {
  console.log("DELETANDO Bloodcenter", payload)
  try {
    const number: number = yield call(api.delete, 'bloodcenter/' + payload.payload)
    console.log("NUMBER", number)
    yield put(deleteBloodcenterSuccess(number))
  } catch (error) {
    yield put(deleteBloodcenterFailure())
  }
}
