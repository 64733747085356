import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {createComponentRequest} from '../../../../store/ducks/component/actions'
import {Component as Comp} from '../../../../store/ducks/component/types'
import {ApplicationState} from '../../../../store'
import { Campaign } from '../../../../store/ducks/campaign/types'
import { createCampaignRequest } from '../../../../store/ducks/campaign/actions'
import axios from 'axios'
import { loadStateRequest } from '../../../../store/ducks/state/actions'

import { loadCityRequest } from '../../../../store/ducks/city/actions'
//import {CKEditor} from 'ckeditor4-react'
import api from '../../../../services/api'
import Loading from '../../../loading'
import { createBloodcenterRequest } from '../../../../store/ducks/bloodcenter/actions'
import { Bloodcenter } from '../../../../store/ducks/bloodcenter/types'
const MOMENT = require('moment')
require("moment-duration-format");

// import { Modal } from 'react-bootstrap'
type ParamTypes = {
  id: string
}

interface handleCloseProps {
  handleClose: () => void
}

const Create = ({handleClose}: handleCloseProps) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')
  const [address, setAddress] = useState('')
  const [bccity, setBccity] = useState('')
  const [bcstate, setBcstate] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [region, setRegion] = useState('')
  const [email, setEmail] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>()
  // const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [preview, setPreview] = useState<any>()
  //const [ckEditor, setCkEditor] = useState(false)

  console.log("SELECTED FILE", selectedFile)
  const [validated, setValidated] = useState(false)
  const {id} = useParams<ParamTypes>()
  // const history = useHistory();
  const dispatch = useDispatch()
  // const component = useSelector((state: ApplicationState) => state.component)
  const me = useSelector((state: ApplicationState) => state.me)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)

  useEffect(() => {
    dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
  }, []) 

  console.log("state", state)
  console.log("city", city)

  // console.log('Component inside - create', component)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    console.log("Create campaign")

    if (selectedFile && name && isSelected && address && bcstate && bccity && phone && region && description) {
      const formdata = new FormData()
      formdata.append('file', selectedFile, selectedFile.name)
      console.log('[formData]', formdata)
      console.log('selectedFile', selectedFile)
      api.post('/upload', formdata, {})
        .then((res) => {
          // then print response status
            var data = new Date()
            const bc: Bloodcenter = {
              name,
              image: res.data.filename,
              address,
              bccity,
              bcstate,
              country,
              status: 1,
              phone,
              region,
              email,
              description
            }
            console.log('bc to save:', bc)
            dispatch(createBloodcenterRequest(bc))
            handleClose()
            // history.goBack()
        })
    }

    
  }

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)
  }

  const setState = (id: string) => {
    setBcstate(id)
    dispatch(loadCityRequest(id!))
  }
  

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
      <div className='row g-5 gx-xxl-12'>
        <div className='col-xxl-6'>
          <h5>Dados do hemocentro</h5><br/>
          <Form.Group controlId='fromName'>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={name}
              onChange={(e:any) => setName(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
          </Form.Group>
          <br />
          <Form.Group controlId='fromName'>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={description}
              onChange={(e:any) => setDescription(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe a descrição</Form.Control.Feedback>
          </Form.Group>
          <br />
          <Form.Group controlId='fromName'>
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={phone}
              onChange={(e:any) => setPhone(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o telefone</Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Form.Group controlId='fromName'>
            <Form.Label>Região</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={region}
              onChange={(e:any) => setRegion(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe a região. Ex: Norte</Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Form.Group controlId='fromName'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={email}
              onChange={(e:any) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o email</Form.Control.Feedback>
          </Form.Group>
          <br/>

          
          <br/>
          <Form.Group>
            <Form.Label>Selecione uma foto</Form.Label>
            <Form.Control
              required
              name='file'
              id='exampleFormControlFile1'
              type='file'
              // label="Selecione um arquivo"
              onChange={changeHandler}
            />
            <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
          </Form.Group>
          <br />
          {selectedFile &&  <img src={preview} style={{width: '50%'}} />}
          <br/><br/>
        </div>
        <div className='col-xxl-6'>
        <h5>Dados do Hemocentro</h5><br/>
            <Form.Group controlId='fromName'>
              <Form.Label>Endereço</Form.Label>
              <Form.Control placeholder='' value={address} onChange={(e:any) => setAddress(e.target.value)} required/>
              <Form.Control.Feedback type='invalid'>Por favor informe o endereço do hemocentro</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId="formBasicSelect">
            <Form.Label>Estado</Form.Label>

            
            {state.loading?<Loading/>:
              <Form.Control
                as="select"
                value={bcstate}
                onChange={(e:any) => setState(e.target.value)}
                required
              >
                <option value="" selected disabled hidden>Selecione um estado</option>
                <option value="" selected disabled hidden>Selecione um estado</option>
                {state.data.map(st => {
                  return <option value={st.id}>{st.name}</option>
                })}
                </Form.Control>
                }
            </Form.Group>
            
            <br />


            <Form.Group controlId="formBasicSelect">
            <Form.Label>Cidade</Form.Label>
            {city.loading?<Loading/>:
              <Form.Control
                required
                disabled={!bcstate}
                as="select"
                value={bccity}
                onChange={(e:any) => setBccity(e.target.value)}
              >
                <option value="" selected disabled hidden>Selecione uma cidade</option>
                {city.data.map((ct) => {
                  return <option value={ct.id}>{ct.name}</option>
                })}
                </Form.Control>
              }
            </Form.Group>



            <br />
          </div>
        </div>
        

        

        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
      {/* Deixar o button fora do form.. */}
    </>
  )
}
export default Create
