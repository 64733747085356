// import { ComponentAvailable } from '../componentavailable/types';
import { City } from '../city/types'
import { User } from '../me/types'
import { State } from '../state/types'
/**
 * Action types
 */
export enum BloodcenterTypes {
  //Load campaigns
  LOAD_BLOODCENTERS_REQUEST = '@bloodcenter/LOAD_BLOODCENTERS_REQUEST',
  LOAD_BLOODCENTERS_SUCCESS = '@bloodcenter/LOAD_BLOODCENTERS_SUCCESS',
  LOAD_BLOODCENTERS_FAILURE = '@bloodcenter/LOAD_BLOODCENTERS_FAILURE',

  //Campaign
  LOAD_BLOODCENTER_REQUEST = '@bloodcenter/LOAD_BLOODCENTER_REQUEST',
  LOAD_BLOODCENTER_SUCCESS = '@bloodcenter/LOAD_BLOODCENTER_SUCCESS',
  LOAD_BLOODCENTER_FAILURE = '@bloodcenter/LOAD_BLOODCENTER_FAILURE',

  //Create component
  CREATE_BLOODCENTER_REQUEST = '@bloodcenter/CREATE_BLOODCENTER_REQUEST',
  CREATE_BLOODCENTER_SUCCESS = '@bloodcenter/CREATE_BLOODCENTER_SUCCESS',
  CREATE_BLOODCENTER_FAILURE = '@bloodcenter/CREATE_BLOODCENTER_FAILURE',

  //Update component
  UPDATE_BLOODCENTER_REQUEST = '@bloodcenter/UPDATE_BLOODCENTER_REQUEST',
  UPDATE_BLOODCENTER_SUCCESS = '@bloodcenter/UPDATE_BLOODCENTER_SUCCESS',
  UPDATE_BLOODCENTER_FAILURE = '@bloodcenter/UPDATE_BLOODCENTER_FAILURE',

  //Delete component
  DELETE_BLOODCENTER_REQUEST = '@bloodcenter/DELETE_BLOODCENTER_REQUEST',
  DELETE_BLOODCENTER_SUCCESS = '@bloodcenter/DELETE_BLOODCENTER_SUCCESS',
  DELETE_BLOODCENTER_FAILURE = '@bloodcenter/DELETE_BLOODCENTER_FAILURE',

  //Search
  SEARCH_BLOODCENTER_REQUEST = '@bloodcenter/SEARCH_BLOODCENTER_REQUEST',
  SEARCH_BLOODCENTER_SUCCESS = '@bloodcenter/SEARCH_BLOODCENTER_SUCCESS',
  SEARCH_BLOODCENTER_FAILURE = '@bloodcenter/SEARCH_BLOODCENTER_FAILURE',

}

/**
 * Data types
 */
// User Imported from Me
export interface Bloodcenter {
  id?: number
  name?: string
  image?: string
  status?: number | boolean

  address?: string
  bccity?: string
  bcstate?: string
  country?: string

  phone?: string
  region?: string
  email?: string
  description?: string
  
  cityParent?: City
  stateParent?: State

}
/**
 * State type
 */
export interface BloodcenterState {
  readonly data: Bloodcenter[]
  readonly loading: boolean
  readonly error: boolean

  readonly bloodcenter: Bloodcenter
  readonly loadingBloodcenter: boolean
  readonly errorBloodcenter: boolean

  readonly search: Bloodcenter[]
  readonly loadingSearch: boolean
  readonly errorSearch: boolean
  
}
