import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import { updateCampaignRequest } from '../../../../store/ducks/campaign/actions'
import { Campaign } from '../../../../store/ducks/campaign/types'
import { loadCityRequest } from '../../../../store/ducks/city/actions'
import {updateComponentRequest} from '../../../../store/ducks/component/actions'
//import {CKEditor} from 'ckeditor4-react'
import {Component} from '../../../../store/ducks/component/types'
import { loadStateRequest } from '../../../../store/ducks/state/actions'
import api from '../../../../services/api'
import Loading from '../../../loading'
import { Bloodcenter } from '../../../../store/ducks/bloodcenter/types'
import { updateBloodcenterRequest } from '../../../../store/ducks/bloodcenter/actions'

const MOMENT = require('moment')
require("moment-duration-format");

interface handleCloseProps {
  handleClose: () => void
  child: Bloodcenter
}

const Update = ({handleClose, child}: handleCloseProps) => {
  const dispatch = useDispatch()
  const [validated, setValidated] = useState(false)
  const bloodcenter = useSelector((state: ApplicationState) => state.bloodcenter)
  const me = useSelector((state: ApplicationState) => state.me)
  
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')
  const [address, setAddress] = useState('')
  const [bccity, setBccity] = useState('')
  const [bcstate, setBcstate] = useState('')
  const [bccountry, setBccountry] = useState('')
  const [phone, setPhone] = useState('')
  const [region, setRegion] = useState('')
  const [email, setEmail] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>()
  // const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [preview, setPreview] = useState<any>()
  //const [ckEditor, setCkEditor] = useState(false)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)

  useEffect(() => {
    setName(child.name!)
    setImage(child.image!)
    // setIsSelected(true)
    // setSelectedFile(child.image!)
    setAddress(child.address!)
    setBccity(''+child.cityParent?.id!)
    setBcstate(''+child.stateParent?.id!)

    setPhone(child.phone!)
    setRegion(child.region!)
    setEmail(child.email!)
    setDescription(child.description!)
    //setBccountry(child.bccountry!)

    //Carrega estados e cidades desse estado
    dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
    dispatch(loadCityRequest(''+child.stateParent?.id!))
  
  }, [child.name])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //console.log('submit', campaign.data.id)
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    if (name && address && bcstate && bccity && phone && region && description) {
      if (selectedFile) {
        //Se selecionou arquivo novo
        console.log("Selecionado novo arquivo")
        const formdata = new FormData()
        formdata.append('file', selectedFile, selectedFile.name)
        console.log('[formData]', formdata)
        console.log('selectedFile', selectedFile)
        api.post('/upload', formdata, {})
          .then((res:any) => {
            
              var data = new Date()
              const bcUpdate: Bloodcenter = {
                id: child.id,
                name,
                image: res.data.filename,
                address,
                bccity,
                bcstate,
                //country,
                status: 1,
                phone,
                region,
                email,
                description
              }
        
              console.log('------------------ bcUpdate TO UPDATE', bcUpdate)
              dispatch(updateBloodcenterRequest(bcUpdate))
              handleClose()
            
          })
      } else {
        //Se não selecionou nenhuma foto nova:
        console.log("Nao selecionou nenhum arquivo")
        var data = new Date()
        const bcToUpdate: Bloodcenter = {
          id: child.id,
          name,
          address,
          bccity,
          bcstate,
          //country,
          status: 1,
          phone,
          region,
          email,
          description
        }

        console.log('------------------ COMPONENT TO UPDATE', bcToUpdate)
        dispatch(updateBloodcenterRequest(bcToUpdate))
        handleClose()
      }
    }

    
  }
  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)
  }

  const setState = (id: string) => {
    setBcstate(id)
    setBccity('')
    dispatch(loadCityRequest(id))
  }

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
      <div className='row g-5 gx-xxl-12'>
        <div className='col-xxl-6'>
          <h5>Dados do hemocentro</h5><br/>
          <Form.Group controlId='fromName'>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={name}
              onChange={(e:any) => setName(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
          </Form.Group>
          <br />
          <Form.Group controlId='fromName'>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={description}
              onChange={(e:any) => setDescription(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe a descrição</Form.Control.Feedback>
          </Form.Group>
          <br />

          <Form.Group controlId='fromName'>
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={phone}
              onChange={(e:any) => setPhone(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
          </Form.Group>
          <br />

          <Form.Group controlId='fromName'>
            <Form.Label>Região</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={region}
              onChange={(e:any) => setRegion(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
          </Form.Group>
          <br />
          <Form.Group controlId='fromName'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={email}
              onChange={(e:any) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o email</Form.Control.Feedback>
          </Form.Group>
          <br />
          
          

          <Form.Group>
            <Form.Label>Selecione uma foto</Form.Label>
            <Form.Control
              name='file'
              id='exampleFormControlFile1'
              type='file'
              onChange={changeHandler}
            />
            <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
          </Form.Group>
          <br />
          {image && !selectedFile && <img src={'https://salvemaisum.com.br/files/' + image} style={{width: '50%'}} />}
          {selectedFile &&  <img src={preview} style={{width: '50%'}} />}
          <br/><br/>
        </div>
        <div className='col-xxl-6'>
        <h5>Dados do Hemocentro</h5><br/>
            
            <Form.Group controlId='fromName'>
              <Form.Label>Endereço</Form.Label>
              <Form.Control placeholder='' value={address} onChange={(e:any) => setAddress(e.target.value)} required/>
              <Form.Control.Feedback type='invalid'>Por favor informe o endereço do hemocentro</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId="formBasicSelect">
            <Form.Label>Estado</Form.Label>

            {state.loading?<Loading/>:
              <Form.Control
                as="select"
                value={bcstate}
                onChange={(e:any) => setState(e.target.value)}
                required
              >
                <option value="" selected disabled hidden>Selecione</option>
                {state.data.map((st:any) => {
                  return <option value={st.id} selected={st.id === +bcstate}>{st.name}</option>
                })}
                </Form.Control>
                }
            </Form.Group>
            <br/>
            <Form.Group controlId="formBasicSelect">
            <Form.Label>Cidade</Form.Label>
            {city.loading?<Loading/>:
              <Form.Control
                as="select"
                value={bccity}
                onChange={(e:any) => setBccity(e.target.value)}
                required
              >
                <option value="" selected disabled hidden>Selecione</option>
                {city.data.map((ct:any) => {
                  return <option value={ct.id} selected={ct.id === +bccity}>{ct.name}</option>
                })}
                </Form.Control>
              }
            </Form.Group>
            <br />
            
          </div>
        </div>
        

        

        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default Update
