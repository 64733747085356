import {Reducer} from 'redux'
import {CampaignState, CampaignTypes} from './types'

const INITIAL_STATE: CampaignState = {
  data: [],
  error: false,
  loading: false,
  
  campaign: {},
  errorCampaign: false,
  loadingCampaign: false,
  
  mycampaigns: [],
  errorMycampaigns: false,
  loadingMycampaigns: false,

  search: [],
  loadingSearch: false,
  errorSearch: false,

  page: 0,

}

const reducer: Reducer<CampaignState> = (state = INITIAL_STATE, action) => {
  console.log('################################Reducer inside Component: ' + action.type + ':', action.payload)
  switch (action.type) {
    //all users:
    case CampaignTypes.LOAD_CAMPAIGNS_REQUEST:
      return {...state, loading: true}
    case CampaignTypes.LOAD_CAMPAIGNS_SUCCESS:
      return {...state, loading: false, error: false, data: state.data.concat(action.payload.data.data), count: action.payload.data.count}
    case CampaignTypes.LOAD_CAMPAIGNS_FAILURE:
      return {...state, loading: false, error: true, data: []}

    case CampaignTypes.LOAD_MYCAMPAIGNS_REQUEST:
      return {...state, loadingMycampaigns: true}
    case CampaignTypes.LOAD_MYCAMPAIGNS_SUCCESS:
      return {...state, loadingMycampaigns: false, errorMycampaigns: false, mycampaigns: action.payload.data}
    case CampaignTypes.LOAD_MYCAMPAIGNS_FAILURE:
      return {...state, loadingMycampaigns: false, errorMycampaigns: true, mycampaigns: []}


    case CampaignTypes.SEARCH_CAMPAIGN_REQUEST:
      return {...state, loadingSearch: true}
    case CampaignTypes.SEARCH_CAMPAIGN_SUCCESS:
      return {...state, loadingSearch: false, errorSearch: false, search: action.payload.data}
    case CampaignTypes.SEARCH_CAMPAIGN_FAILURE:
      return {...state, loadingSearch: false, errorSearch: true, search: []}

    case CampaignTypes.LOAD_CAMPAIGN_REQUEST:
      return {...state, loadingCampaign: true}
    case CampaignTypes.LOAD_CAMPAIGN_SUCCESS:
      return {...state, loadingCampaign: false, errorCampaign: false, campaign: action.payload.data}
    case CampaignTypes.LOAD_CAMPAIGN_FAILURE:
      return {...state, loadingCampaign: false, errorCampaign: true, campaign: {}}

    //Create
    case CampaignTypes.CREATE_CAMPAIGN_REQUEST:
      return {...state}
    case CampaignTypes.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state, 
        loadingMycampaigns: false, 
        error: false, 
        mycampaigns: [action.payload.data, ...state.mycampaigns],
        data: [action.payload.data, ...state.data],
        count: state.count!+1
      }
    case CampaignTypes.CREATE_CAMPAIGN_FAILURE:
      return {...state, loadingMycampaigns: false, error: true}

    //Update
    case CampaignTypes.UPDATE_CAMPAIGN_REQUEST:
      return {...state}
    case CampaignTypes.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state, 
        loading: false, 
        error: false,
        mycampaigns: state.mycampaigns.map((child) =>
          child.id === action.payload.data.id ? action.payload.data : child
        ),
        data: state.data.map((child) =>
          child.id === action.payload.data.id ? action.payload.data : child
        )
    }
    case CampaignTypes.UPDATE_CAMPAIGN_FAILURE:
      return {...state, loading: false, error: true}

    //Delete
    case CampaignTypes.DELETE_CAMPAIGN_REQUEST:
      return {...state}
    case CampaignTypes.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state, 
        loadingMycampaigns: false, 
        errorMycampaigns: false, 
        mycampaigns: state.mycampaigns.filter((item) => item.id !== action.payload.data),
        data: state.data.filter((item) => item.id !== action.payload.data),
        count: state.count!-1
      }
    case CampaignTypes.DELETE_CAMPAIGN_FAILURE:
      return {...state, loadingMycampaigns: false, errorMycampaigns: true, mycampaigns: []}
    default:
      return state
  }
}

export default reducer
