import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import { updateCampaignRequest } from '../../../../store/ducks/campaign/actions'
import { Campaign } from '../../../../store/ducks/campaign/types'
import { loadCityRequest } from '../../../../store/ducks/city/actions'
import {updateComponentRequest} from '../../../../store/ducks/component/actions'
//import {CKEditor} from 'ckeditor4-react'
import {Component} from '../../../../store/ducks/component/types'
import { loadStateRequest } from '../../../../store/ducks/state/actions'
import api from '../../../../services/api'
import Loading from '../../../loading'

const MOMENT = require('moment')
require("moment-duration-format");

interface handleCloseProps {
  handleClose: () => void
  child: Campaign
}

const Update = ({handleClose, child}: handleCloseProps) => {
  const dispatch = useDispatch()
  const [validated, setValidated] = useState(false)
  const campaign = useSelector((state: ApplicationState) => state.campaign)
  const me = useSelector((state: ApplicationState) => state.me)
  
  const [name, setName] = useState('')
  const [bloodtype, setBloodtype] = useState('')
  const [image, setImage] = useState('')
  const [bcname, setBcname] = useState('')
  const [bcaddress, setBcaddress] = useState('')
  const [bccity, setBccity] = useState('')
  const [bcstate, setBcstate] = useState('')
  const [bccountry, setBccountry] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>()
  // const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [preview, setPreview] = useState<any>()
  //const [ckEditor, setCkEditor] = useState(false)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)

  useEffect(() => {
    setName(child.name!)
    setBloodtype(child.bloodtype!)
    setImage(child.image!)
    // setIsSelected(true)
    // setSelectedFile(child.image!)
    setBcname(child.bcname!)
    setBcaddress(child.bcaddress!)
    setBccity(''+child.cityParent?.id!)
    setBcstate(''+child.stateParent?.id!)
    //setBccountry(child.bccountry!)

    //Carrega estados e cidades desse estado
    dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
    dispatch(loadCityRequest(''+child.stateParent?.id!))
  
  }, [child.name])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //console.log('submit', campaign.data.id)
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    if (name && bloodtype && bcname && bcaddress && bcstate && bccity) {
      if (selectedFile) {
        //Se selecionou arquivo novo
        console.log("Selecionado novo arquivo")
        const formdata = new FormData()
        formdata.append('file', selectedFile, selectedFile.name)
        console.log('[formData]', formdata)
        console.log('selectedFile', selectedFile)
        api.post('/upload', formdata, {})
          .then((res:any) => {
            
              var data = new Date()
              const campaignToUpdate = {
                id: child.id,
                name,
                bloodtype,
                //user_id: me.me.id!,
                image: res.data.filename,
                bcname,
                bcaddress,
                bccity,
                bcstate,
                bccountry,
                created_at: (data.getTime() / 1000).toString(),
                status: 1,
              }
        
              console.log('------------------ COMPONENT TO UPDATE', campaignToUpdate)
              dispatch(updateCampaignRequest(campaignToUpdate))
              handleClose()
            
          })
      } else {
        //Se não selecionou nenhuma foto nova:
        console.log("Nao selecionou nenhum arquivo")
        var data = new Date()
        const campaignToUpdate = {
          id: child.id,
          name,
          bloodtype,
          //user_id: me.me.id!,
          bcname,
          bcaddress,
          bccity,
          bcstate,
          bccountry,
          created_at: (data.getTime() / 1000).toString(),
          status: 1,
        }

        console.log('------------------ COMPONENT TO UPDATE', campaignToUpdate)
        dispatch(updateCampaignRequest(campaignToUpdate))
        handleClose()
      }
    }

    
  }
  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)
  }

  const setState = (id: string) => {
    setBcstate(id)
    setBccity('')
    dispatch(loadCityRequest(id))
  }

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
      <div className='row g-5 gx-xxl-12'>
        <div className='col-xxl-6'>
          <h5>Dados de quem receberá sangue</h5><br/>
          <Form.Group controlId='fromName'>
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={name}
              onChange={(e:any) => setName(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
          </Form.Group>
          <br />

          <Form.Group controlId="formBasicSelect">
            <Form.Label>Tipo Sanguineo</Form.Label>
            <Form.Control
              required
              as="select"
              value={bloodtype}
              onChange={(e:any) => setBloodtype(e.target.value)}
            >
              <option value="" selected disabled hidden>Selecione um tipo sanguíneo</option>
              <option value="1" selected={bloodtype === '1'?true:false}>O-</option>
              <option value="2" selected={bloodtype === '2'?true:false}>O+</option>
              <option value="3" selected={bloodtype === '3'?true:false}>A-</option>
              <option value="4" selected={bloodtype === '4'?true:false}>A+</option>
              <option value="5" selected={bloodtype === '5'?true:false}>B-</option>
              <option value="6" selected={bloodtype === '6'?true:false}>B+</option>
              <option value="7" selected={bloodtype === '7'?true:false}>AB-</option>
              <option value="8" selected={bloodtype === '8'?true:false}>AB+</option>
            </Form.Control>
          </Form.Group>

          
          <br />

          <Form.Group>
            <Form.Label>Selecione uma foto</Form.Label>
            <Form.Control
              name='file'
              id='exampleFormControlFile1'
              type='file'
              onChange={changeHandler}
            />
            <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
          </Form.Group>
          <br />
          {image && !selectedFile && <img src={'https://salvemaisum.com.br/files/' + image} style={{width: '50%'}} />}
          {selectedFile &&  <img src={preview} style={{width: '50%'}} />}
          <br/><br/>
        </div>
        <div className='col-xxl-6'>
        <h5>Dados do Hemocentro</h5><br/>
            <Form.Group controlId='fromName'>
              <Form.Label>Nome do Hemocentro</Form.Label>
              <Form.Control placeholder='' value={bcname} onChange={(e:any) => setBcname(e.target.value)} required/>
              <Form.Control.Feedback type='invalid'>Por favor informe o nome do hemocentro</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId='fromName'>
              <Form.Label>Endereço</Form.Label>
              <Form.Control placeholder='' value={bcaddress} onChange={(e:any) => setBcaddress(e.target.value)} required/>
              <Form.Control.Feedback type='invalid'>Por favor informe o endereço do hemocentro</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId="formBasicSelect">
            <Form.Label>Estado</Form.Label>

            {state.loading?<Loading/>:
              <Form.Control
                as="select"
                value={bcstate}
                onChange={(e:any) => setState(e.target.value)}
                required
              >
                <option value="" selected disabled hidden>Selecione</option>
                {state.data.map((st:any) => {
                  return <option value={st.id} selected={st.id === +bcstate}>{st.name}</option>
                })}
                </Form.Control>
                }
            </Form.Group>
            <br/>
            <Form.Group controlId="formBasicSelect">
            <Form.Label>Cidade</Form.Label>
            {city.loading?<Loading/>:
              <Form.Control
                as="select"
                value={bccity}
                onChange={(e:any) => setBccity(e.target.value)}
                required
              >
                <option value="" selected disabled hidden>Selecione</option>
                {city.data.map((ct:any) => {
                  return <option value={ct.id} selected={ct.id === +bccity}>{ct.name}</option>
                })}
                </Form.Control>
              }
            </Form.Group>
            <br />
            
          </div>
        </div>
        

        

        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default Update
