import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {createComponentRequest} from '../../../../store/ducks/component/actions'
import {Component as Comp} from '../../../../store/ducks/component/types'
import {ApplicationState} from '../../../../store'
import { Campaign } from '../../../../store/ducks/campaign/types'
import { createCampaignRequest } from '../../../../store/ducks/campaign/actions'
import axios from 'axios'
import { loadStateRequest } from '../../../../store/ducks/state/actions'

import { loadCityRequest } from '../../../../store/ducks/city/actions'
//import {CKEditor} from 'ckeditor4-react'
import api from '../../../../services/api'
import Loading from '../../../loading'
const MOMENT = require('moment')
require("moment-duration-format");

// import { Modal } from 'react-bootstrap'
type ParamTypes = {
  id: string
}

interface handleCloseProps {
  handleClose: () => void
}

const Create = ({handleClose}: handleCloseProps) => {
  const [name, setName] = useState('')
  const [bloodtype, setBloodtype] = useState('')
  const [image, setImage] = useState('')
  const [bcname, setBcname] = useState('')
  const [bcaddress, setBcaddress] = useState('')
  const [bccity, setBccity] = useState('')
  const [bcstate, setBcstate] = useState('')
  const [bccountry, setBccountry] = useState('')
  const [selectedFile, setSelectedFile] = useState<any>()
  // const [isFilePicked, setIsFilePicked] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [preview, setPreview] = useState<any>()
  //const [ckEditor, setCkEditor] = useState(false)

  console.log("SELECTED FILE", selectedFile)
  const [validated, setValidated] = useState(false)
  const {id} = useParams<ParamTypes>()
  // const history = useHistory();
  const dispatch = useDispatch()
  // const component = useSelector((state: ApplicationState) => state.component)
  const me = useSelector((state: ApplicationState) => state.me)
  const state = useSelector((state: ApplicationState) => state.state)
  const city = useSelector((state: ApplicationState) => state.city)

  useEffect(() => {
    dispatch(loadStateRequest()) //Puxa componentes com seus filhos primários
  }, []) 

  console.log("state", state)
  console.log("city", city)

  // console.log('Component inside - create', component)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)
    console.log("Create campaign")

    if (selectedFile && name && bloodtype && isSelected && bcname && bcaddress && bcstate && bccity) {
      const formdata = new FormData()
      formdata.append('file', selectedFile, selectedFile.name)
      console.log('[formData]', formdata)
      console.log('selectedFile', selectedFile)
      api.post('/upload', formdata, {})
        .then((res) => {
          // then print response status
            var data = new Date()
            const campaign: Campaign = {
              name,
              bloodtype,
              user_id: me.me.id!,
              image: res.data.filename,
              bcname,
              bcaddress,
              bccity,
              bcstate,
              bccountry,
              created_at: (data.getTime() / 1000).toString(),
              status: 1,
            }
            console.log('campaign to save:', campaign)
            dispatch(createCampaignRequest(campaign))
            handleClose()
            // history.goBack()
        })
    }

    
  }

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0])
    setIsSelected(true)
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)
  }

  const setState = (id: string) => {
    setBcstate(id)
    dispatch(loadCityRequest(id!))
  }
  

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
      <div className='row g-5 gx-xxl-12'>
        <div className='col-xxl-6'>
          <h5>Dados de quem receberá sangue</h5><br/>
          <Form.Group controlId='fromName'>
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              placeholder=''
              required
              value={name}
              onChange={(e:any) => setName(e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Por favor informe o nome</Form.Control.Feedback>
          </Form.Group>
          <br />

          <Form.Group controlId="formBasicSelect">
            <Form.Label>Tipo Sanguineo</Form.Label>
            <Form.Control
              required
              as="select"
              value={bloodtype}
              onChange={(e:any) => setBloodtype(e.target.value)}
            >
              <option value="" selected disabled hidden>Selecione um tipo sanguíneo</option>
              <option value="1">O-</option>
              <option value="2">O+</option>
              <option value="3">A-</option>
              <option value="4">A+</option>
              <option value="5">B-</option>
              <option value="6">B+</option>
              <option value="7">AB-</option>
              <option value="8">AB+</option>
            </Form.Control>
          </Form.Group>
          <br/>
          <Form.Group>
            <Form.Label>Selecione uma foto</Form.Label>
            <Form.Control
              required
              name='file'
              id='exampleFormControlFile1'
              type='file'
              // label="Selecione um arquivo"
              onChange={changeHandler}
            />
            <Form.Control.Feedback type='invalid'>Selecione um arquivo</Form.Control.Feedback>
          </Form.Group>
          <br />
          {selectedFile &&  <img src={preview} style={{width: '50%'}} />}
          <br/><br/>
        </div>
        <div className='col-xxl-6'>
        <h5>Dados do Hemocentro</h5><br/>
            <Form.Group controlId='fromName'>
              <Form.Label>Nome do Hemocentro</Form.Label>
              <Form.Control placeholder='' value={bcname} onChange={(e:any) => setBcname(e.target.value)} required/>
              <Form.Control.Feedback type='invalid'>Por favor informe o nome do hemocentro</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId='fromName'>
              <Form.Label>Endereço</Form.Label>
              <Form.Control placeholder='' value={bcaddress} onChange={(e:any) => setBcaddress(e.target.value)} required/>
              <Form.Control.Feedback type='invalid'>Por favor informe o endereço do hemocentro</Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group controlId="formBasicSelect">
            <Form.Label>Estado</Form.Label>

            
            {state.loading?<Loading/>:
              <Form.Control
                as="select"
                value={bcstate}
                onChange={(e:any) => setState(e.target.value)}
                required
              >
                <option value="" selected disabled hidden>Selecione um estado</option>
                <option value="" selected disabled hidden>Selecione um estado</option>
                {state.data.map(st => {
                  return <option value={st.id}>{st.name}</option>
                })}
                </Form.Control>
                }
            </Form.Group>
            
            <br />


            <Form.Group controlId="formBasicSelect">
            <Form.Label>Cidade</Form.Label>
            {city.loading?<Loading/>:
              <Form.Control
                required
                disabled={!bcstate}
                as="select"
                value={bccity}
                onChange={(e:any) => setBccity(e.target.value)}
              >
                <option value="" selected disabled hidden>Selecione uma cidade</option>
                {city.data.map((ct) => {
                  return <option value={ct.id}>{ct.name}</option>
                })}
                </Form.Control>
              }
            </Form.Group>



            <br />
          </div>
        </div>
        

        

        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
      {/* Deixar o button fora do form.. */}
    </>
  )
}
export default Create
